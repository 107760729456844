import React from 'react'
import Ad from '../components/atoms/ad/Ad'
import { colors } from '../const/colors'
import { spaceTopDefault } from '../const/space'
import DefaultLayout from '../layouts/DefaultLayout'
import HeadMeta from '../components/organisms/HeadMeta'
import LinkButtonDefault from '../components/atoms/buttons/LinkButtonDefault'
import { joinSpace } from '../libs/utils'
import * as styles from '../css/pages/404.module.styl'

const NotFoundPage: React.FC = () => {
  return (
    <DefaultLayout>
      {' '}
      <HeadMeta title="ページが見つかりませんでした" />{' '}
      <article className={joinSpace(['pt-4'])}>
        <h2 className={joinSpace([styles.notFoundHeadline, 'ff-headline'])}>
          <span className={joinSpace([styles.notFoundHeadlineColor])}>404</span>{' '}
          Not Found{' '}
        </h2>
        <div className={spaceTopDefault}>
          <h3 className={joinSpace([])}>
            お探しのページは見つかりません
            <wbr />
            <span className="nowrap">でした。</span>{' '}
          </h3>
          <p className={joinSpace(['mb-0', 'pt-6'])}>
            ページが移動している、もしくは削除されている可能性があります。{' '}
          </p>
        </div>

        {/* AD */}
        <aside className={joinSpace(['pt-10', 'pb-10'])}>
          <Ad
            adSlot="5598200535"
            adFormat="auto"
            adResponsive="true"
            adStyle={{ display: 'block' }}
          />
        </aside>

        <div className={joinSpace([styles.notFoundGoHome])}>
          <LinkButtonDefault target="/" name="HOME" color={colors.red} />
        </div>
      </article>
    </DefaultLayout>
  )
}

export default NotFoundPage
